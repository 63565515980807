import { OddsVariant } from '@/config/odds/OddsVariant';
import { DateFormat, formatDate } from './dateUtils';

const videoDescriptionTexts = {
  lotto:
    'Die Ziehung der Gewinnzahlen von LOTTO 6aus49 wird jeden Mittwoch und Samstag live aus dem LOTTO-Studio in Saarbrücken übertragen. Die Ziehungstrommel mit den Lottokugeln von 1 bis 49 beginnt sich zu drehen und die Moderatorin präsentiert die sechs gezogenen Zahlen. Im Anschluss startet die Ziehung der Superzahl mit den Kugeln 0 bis 9 in einer separaten Ziehungstrommel. Abschließend werden nochmal alle Gewinnzahlen von LOTTO 6aus49 sowie die Superzahl vorgelesen und die Gewinnzahlen der Zusatzlotterien Spiel 77 und SUPER 6 bekannt gegeben.',
  lotto360:
    'Die Ziehung der Gewinnzahlen von LOTTO 6aus49 wird jeden Mittwoch und Samstag im LOTTO-Studio in Saarbrücken zusätzlich mit einer 360°-Kamera aufgezeichnet, um einen Blick hinter die Kulissen zu ermöglichen. Sie können die Perspektive im Studio frei verändern und dabei die Moderatorin oder das Ziehungsteam bei der Arbeit beobachten. Über das 360°-Video erfahren Sie auch alle gezogenen Zahlen von LOTTO 6aus49 inklusive der Superzahl sowie die Gewinnzahlen der Zusatzlotterien Spiel 77 und SUPER 6.',
  eurojackpot:
    'Die Ziehung der Eurojackpot-Gewinnzahlen wird jeden Dienstag und Freitag aus einem Studio in der finnischen Hauptstadt Helsinki übertragen. Die Ziehungstrommel mit den Kugeln von 1 bis 50 beginnt sich zu drehen und die fünf Gewinnzahlen rollen automatisch aus dem Ziehungsgerät. Im Anschluss startet die Ziehung der zwei Eurozahlen mit den Kugeln 1 bis 12 in einer separaten Ziehungstrommel.',
  keno: 'Die Ziehung der KENO-Gewinnzahlen wird täglich live in einem Studio in Wiesbaden von der Moderatorin Annett Fleischer präsentiert. Das Ziehungsgerät arbeitet mit einem Zufallsgenerator und ermittelt die 20 KENO-Gewinnzahlen sowie die Gewinnzahlen der Zusatzlotterie plus 5 automatisch. Abschließend werden nochmal alle gezogenen Zahlen in einer Zusammenfassung gezeigt und vorgelesen.',
};

const dateTexts = {
  lotto: 'Die Gewinnzahlen der Ziehung vom ${drawDate} lauten:',
  lotto360: 'Die Gewinnzahlen der Ziehung vom ${drawDate} lauten:',
  eurojackpot:
    'Die Gewinnzahlen der Eurojackpot-Ziehung vom ${drawDate} lauten:',
  keno: 'Die Gewinnzahlen der KENO-Ziehung vom ${drawDate} lauten:',
};

const printCollection = function (collection): string {
  return collection
    .map((item) => {
      return item.drawNumber;
    })
    .sort((a, b) => a - b)
    .join(', ');
};
export const splitWinningNumbers = function (numbers): string {
  return numbers.split('').join(' ');
};

export function formDrawVideoNumbers(drawData: any, variant: string) {
  if (drawData?.drawDate) {
    let numbersText = '';

    switch (variant) {
      case 'lotto':
      case 'lotto360':
      case 'lotto6aus49':
        if (drawData.drawNumbersCollection) {
          numbersText = `LOTTO 6aus49: ${printCollection(
            drawData.drawNumbersCollection
          )}`;
          if (drawData.extraNumber)
            numbersText += `, Zusatzzahl ${drawData.extraNumber}`;
          if (drawData.superNumber)
            numbersText += `, Superzahl ${drawData.superNumber}`;
          if (drawData.game77?.numbers) {
            numbersText += `<br />Spiel 77: ${splitWinningNumbers(
              drawData.game77.numbers
            )}`;
          }
          if (drawData.super6?.numbers) {
            numbersText += `<br />SUPER 6: ${splitWinningNumbers(
              drawData.super6.numbers
            )}`;
          }
        }
        break;

      case OddsVariant.Eurojackpot:
        if (drawData.drawNumbersCollection) {
          const sortedCollection = drawData.drawNumbersCollection
            .filter((drawNumber) => drawNumber.drawNumberType === 0)
            .sort((a, b) => (a.drawNumber < b.drawNumber ? -1 : 1));
          const sortedExtraCollection = drawData.drawNumbersCollection
            .filter((drawNumber) => drawNumber.drawNumberType === 1)
            .sort((a, b) => (a.drawNumber < b.drawNumber ? -1 : 1));
          numbersText =
            printCollection(sortedCollection) +
            ', Eurozahlen: ' +
            printCollection(sortedExtraCollection);
        }
        break;

      case OddsVariant.Keno:
        if (drawData.drawNumbers && drawData.plus5) {
          numbersText =
            'KENO: ' +
            drawData.drawNumbers +
            '<br />' +
            'plus 5: ' +
            splitWinningNumbers(drawData.plus5);
        }
        break;

      default:
        break;
    }

    return (
      numbersText +
      (numbersText
        ? '<br /><br /><div class="WinningNumbers__notice">Alle Angaben ohne Gewähr.</div>'
        : '')
    );
  }
  return '';
}

export function printVideoDescription(
  description: any,
  drawData?: any,
  variant?: string
) {
  if (!description) {
    return '';
  }
  if (!drawData || !variant) {
    return description;
  }
  const drawDate = formatDate(drawData.drawDate, DateFormat.Extended);
  return (
    description +
    '<br /><br />' +
    dateTexts[variant].replace('${drawDate}', drawDate) +
    '<br /><br />' +
    formDrawVideoNumbers(drawData, variant)
  );
}

export default function formDrawVideoDescription(
  drawData: any,
  variant: string
) {
  const description = videoDescriptionTexts[variant];
  if (!description) {
    return '';
  }
  if (!drawData) {
    return description;
  }
  return printVideoDescription(description, drawData, variant);
}
